import React from "react";
import {Helmet} from "react-helmet";

import Layout from "../components/Layout";
import NotFound from "../components/NotFound/NotFound";

import Favicon from "../images/Favicon.png";

const NotFoundPage = () => {
  return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Page not found - Drivosity</title>
          <link rel="icon" type="image/png" sizes="32x32" href={Favicon} />
        </Helmet>
        <Layout component={<NotFound />} />
      </>
  )
}

export default NotFoundPage;
