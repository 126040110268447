import React from "react";
import {AiOutlineArrowRight} from "react-icons/ai";

import DrvCover from "../Shared/DrvCover/DrvCover";
import {DrvLinkPath} from "../Shared/DrvLink/DrvLinkPath";
import {DrvButton} from "../Shared/DrvButton/DrvButton";
import './NotFound.css';

import Cover from "../../images/NotFound/Cover.jpeg";
import First from "../../images/NotFound/404.jpeg";

const NotFound = () => {
    return (
        <>
            {/*CONTENT*/}
            <DrvCover
                title='PAGE NOT FOUND'
                description=''
                color='#ffffff'
                image={Cover}
            />

            {/*CONTENT*/}
            <div className='container mx-auto' style={{paddingTop: '55px'}}>
                <DrvLinkPath items={[{ text: 'Drivosity', link: '/', isLink: true }, { text: '404', link: '/404', isLink: false }]} />
            </div>

            {/*CONTENT*/}
            <div className='py-12 container mx-auto flex flex-col lg:flex-row gap-x-4 gap-y-4 not-found-container'>
                <div className='flex flex-col flex-1'>
                    <div className='drv-subtitle'>
                        ERROR 404:<br/>
                        PAGE NOT FOUND
                    </div>
                    <div className='drv-description' style={{color: 'var(--text-color)'}}>
                        <div className='mb-7'>
                            The page you were looking for is not here.
                        </div>
                        <DrvButton to="/"
                                   background="transparent"
                                   color="#545454"
                                   hoverbackground="var(--primary-color)"
                                   hoverborder="var(--primary-color)"
                                   hovercolor="#fff"
                                   className="w-fit"
                        >
                            HOME PAGE
                            <AiOutlineArrowRight style={{fontSize: "17px"}} className="ml-3" />
                        </DrvButton>
                    </div>
                </div>

                <div className='flex flex-col flex-1 hidden lg:block'>
                    <img src={First} alt="404 Image"/>
                </div>
            </div>
        </>
    )
}

export default NotFound;
